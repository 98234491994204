<template>
  <div id="signature"
       :style="`width: ${width}px;`">
    <div class="row m-0">

      <div v-if="label"
           class="col-12 label p-0">
        {{ label }}
      </div>

      <div v-if="cursiveSignature"
           class="col-12 m-0 p-0">
        <!-- BOX AROUND TEXT -->
        <div class="border row m-0 justify-content-center align-content-center"
             :style="`width: ${width}px; height: ${height}px`">
          <h2 :class="`text-${color}`"
              :style="`font-size: ${calculatedFontSize - 2}px; font-family: '${fontFamily}', serif;`">
            {{ fullName }}
          </h2>
        </div>

      </div>

      <div v-show="!cursiveSignature"
           class="col-12 m-0 p-0">
        <div class="position-relative">

          <!-- RESET-ICON CONTAINER -->
          <div class="position-absolute" style="padding: 5px 8px 0 0; right: 0">
            <BIconArrowCounterclockwise font-scale="1.5" @click="clear"/>
          </div>

          <div @touchend="createSignature"
               @mouseleave="createSignature"
               @mouseup="createSignature">
            <vueSignature ref="signature"
                          class="border signature-canvas"
                          style="max-width: 428px!important; max-height: 200px!important;"
                          :sigOption="{ penColor: themeColors[color], backgroundColor:'transparent'}"
                          :w="`${width}px`"
                          :h="`${height}px`"/>
          </div>

        </div>
      </div>

      <div class="col-12 m-0 p-0 pt-2 text-right">
        <b-form-checkbox v-model="cursiveSignature"
                         checked="true"
                         class="m-0"
                         switch
                         inline>
          Schreibschrift
        </b-form-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import vueSignature from "vue-signature"
import {BFormCheckbox, BIconArrowCounterclockwise} from "bootstrap-vue"

export default {
  name: "Signature",
  props: {
    fullName: String,
    label: String,
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 200
    },
    fontSize: {
      type: Number,
      default: 30
    },
    fontFamily: {
      type: String,
      default: 'Bradley Hand'
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
  components: {
    BIconArrowCounterclockwise,
    BFormCheckbox,
    vueSignature,
  },
  data() {
    return {
      themeColors: ['success'],
      cursiveSignature: false,
      signatureBase64: null,
    }
  },
  watch: {
    cursiveSignature() {
      this.createSignature()
    },
  },
  mounted() {
    let canvasContainer = document.getElementsByClassName('signature-canvas')[0]
    let canvas = canvasContainer.childNodes[0]
    canvas.style.cssText = canvas.style.cssText + ' max-width: 428px!important; max-height: 200px!important'
  },
  computed: {
    calculatedFontSize() {
      let canvas = document.createElement('canvas')
      canvas.width = this.width
      canvas.height = this.height

      let context = canvas.getContext("2d")
      context.font = `${this.fontSize}px ${this.fontFamily}`

      // Check if text fits the canvas or else adjust fontSize
      let fontSize = this.fontSize
      while (context.measureText(this.fullName).width > this.width) {
        fontSize--
        context.font = `${fontSize}px ${this.fontFamily}`
      }
      return fontSize
    }
  },
  methods: {
    setDefaultSignature(imageBase64) {
      this.$refs.signature.fromDataURL(imageBase64 || '')
    },
    createSignature() {
      if (this.cursiveSignature) {
        this.signatureBase64 = this.createBase64FromText()
        this.$emit('created:signature', this.signatureBase64)
      } else if (!this.$refs.signature.isEmpty()) {
        this.signatureBase64 = this.$refs.signature.save()
        this.$emit('created:signature', this.signatureBase64)
      } else {
        this.signatureBase64 = null
        this.$emit('created:signature', this.signatureBase64)
      }
    },
    createBase64FromText() {
      let canvas = document.createElement('canvas')
      canvas.width = this.width
      canvas.height = this.height

      let context = canvas.getContext("2d")
      context.font = `${this.calculatedFontSize}px ${this.fontFamily}`
      context.fillStyle = this.themeColors[this.color]
      context.textAlign = "center"
      context.textBaseline = 'middle'

      context.fillText(this.fullName, this.width / 2, this.height / 2)
      const imgBase64 = canvas.toDataURL('image/png', 1)
      return imgBase64
    },
    clear() {
      this.$refs.signature.clear()
      this.signatureBase64 = null
      this.$emit('created:signature', this.signatureBase64)
    },
  }
}
</script>

<style lang="scss">
#signature {
  .label {
    margin-bottom: 8px;
  }
}
</style>
