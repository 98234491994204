<template>
  <div class="web-approval-tool-container">
    <div v-if="loading"
         class="row justify-content-center py-2">
      <b-spinner label="Spinning"
                 variant="success"></b-spinner>
    </div>

    <div v-if="!loading && !onlineSignature"
         class="p-2 text-center bg-danger text-white">
      Abnahme nicht gefunden!
    </div>

    <div v-if="!loading && onlineSignature"
         :class="{'text-success' : onlineSignature.signed}">
      <div class="row no-gutters align-content-between py-3 px-4">

        <div class="col-auto row no-gutters flex-nowrap align-items-center">
          <img :src="logo"
               class="logo-size"
               alt="Logo">
        </div>

        <div v-if="onlineSignature.signed"
             class="col row no-gutters justify-content-center align-items-center">
          <div>
            <BIconCheckCircle class="default-icon-size mr-1 font-weight-bolder"/>
            Projekt wurde am {{ formatDate(onlineSignature.dateSigned) }} vollständig abgenommen.
          </div>
        </div>

        <div v-if="!onlineSignature.signed"
             class="col row no-gutters justify-content-end align-items-center flex-nowrap">
          <div class="pr-2">
            <b-button @click="approveCurrentView(window.location.href)"
                      class="approve-btn-padding"
                      :disabled="viewAlreadyApproved(window.location.href)"
                      size="sm"
                      style="padding: 5px 30px; font-size: 14px!important;"
                      variant="success">
              <div class="row justify-content-center align-items-center">

                <div>
                  <BIconCheckCircle v-if="viewAlreadyApproved(window.location.href)"
                                    class="default-icon-size"/>
                </div>

                <div style="padding-left: 10px">
                  {{ !viewAlreadyApproved(window.location.href) ? 'Aktuelle Seite abnehmen' : 'Seite abgenommen' }}
                </div>
              </div>
            </b-button>
          </div>
          <div class="pr-2">
            <b-button @click="showModal = true"
                      size="sm"
                      class="approve-btn-padding"
                      style="font-size: 14px!important;"
                      variant="outline-dark">Gesamtes Projekt abnehmen
            </b-button>
          </div>
          <div>
            <b-button size="xs"
                      class="p-0 pb-1 expand-details-btn"
                      variant="outline-dark"
                      @click="toggleDetails">
              <BIconChevronDown v-if="showDetails"
                                class="default-icon-size"/>
              <BIconChevronUp v-if="!showDetails"
                              class="default-icon-size"/>
            </b-button>
          </div>
        </div>
      </div>

      <b-modal v-model="showModal"
               id="approve-info-modal"
               centered
               title="Bestätigung Gesamtabnahme"
               @ok="sign">
        <div class="row m-0 justify-content-center">
          <div class="col-12 row m-0 px-1">
            <div class="col-12 pb-3">
            </div>

            <b-form-group class="col-12"
                          label="Firma, Name, Position"
                          label-for="contact-name">
              <b-form-input id="contact-name"
                            :state="!onlineSignature.contractor.signatureLine.length ? false : null"
                            v-model="onlineSignature.contractor.signatureLine"/>
            </b-form-group>

          </div>
          <div class="col-12 p-0 m-0">
            <div class="row justify-content-center pt-4">
              <Signature ref="signature"
                         label="Hiermit erkläre ich die Abnahme des gesamten Projekts."
                         :full-name="onlineSignature.contractor.fullName"
                         :width="428"
                         :height="200"
                         :font-size="45"
                         font-family="Bradley Hand"
                         @created:signature="updateSignature"
              />
            </div>
          </div>
        </div>

        <template #modal-footer="{ ok, cancel }">
          <b-button size="sm" style="font-size: 14px!important; padding: 6px 10px !important" variant="danger"
                    @click="cancel()">
            Abbrechen
          </b-button>

          <b-button size="sm" style="font-size: 14px!important; padding: 6px 10px !important" variant="success"
                    :disabled="!onlineSignature.contractor.signatureLine.length || !onlineSignature.contractor.signatureImage"
                    @click="ok()">
            Jetzt verbindlich abnehmen
          </b-button>
        </template>
      </b-modal>
      <!-- Approval Details -->
      <div v-if="showDetails && !onlineSignature.signed"
           class="py-3 border-top row no-gutters justify-content-center align-items-center approval-tool-scrollbar">
        <BLink v-for="view in views"
               :key="view.name"
               :href="view.url"
               target="_blank"
               class="col-12 row no-gutters justify-content-between flex-nowrap px-4 py-1 view-element text-decoration-none">
          <span>
            {{ view.url }}
          </span>
          <b-badge :variant="`${viewAlreadyApproved(view.url) ? 'success' : 'secondary'}`"
                   class="px-3 py-2"
                   pill>
            {{
              viewAlreadyApproved(view.url) ? `Abgenommen am ${formatDate(view.approvalDate)}` : 'Besucht'
            }}
          </b-badge>
        </BLink>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BFormGroup,
  BFormInput,
  BIconCheckCircle,
  BIconChevronDown,
  BIconChevronUp,
  BLink,
  BModal,
  BSpinner
} from "bootstrap-vue";
import Api from "@/agency-web-approval-tool/services/Api";
import Signature from "@/components/Signature";

export default {
  name: "AgencyWebApprovalTool",
  components: {
    BButton,
    BLink,
    BSpinner,
    BModal,
    Signature,
    BBadge,
    BFormInput,
    BFormGroup,
    BIconCheckCircle,
    BIconChevronDown,
    BIconChevronUp
  },
  data() {
    return {
      loading: false,
      onlineSignature: null,
      agencyClientApprovalId: null,
      views: [],
      clientIP: null,
      showDetails: false,
      window: window,
      showModal: false,
      logo: null
    }
  },
  mounted() {
    this.init()
    this.getClientIP()

    setInterval(() => {
      if (this.agencyClientApprovalId) {
        this.addView(window.location.href)
      }
    }, 500)
  },
  methods: {
    formatDate(approvalDate) {
      const parsedApprovalDate = new Date(approvalDate)
      let date = parsedApprovalDate.getDate()
      let month = parsedApprovalDate.getMonth() + 1
      let year = parsedApprovalDate.getFullYear()
      let hours = parsedApprovalDate.getHours()
      let minutes = parsedApprovalDate.getMinutes()
      return `${date}.${month}.${year} um ${hours}:${minutes < 10 ? '0' + minutes : minutes}`
    },
    getApprovalIdFromUrl() {
      let approvalId = new URLSearchParams(window.location.search).get('approval')
      if (approvalId) {
        return approvalId
      }
      // check if hashing mode for routing is active
      let urlAndApprovalId = window.location.href.split('approval=')
      if (urlAndApprovalId[1]) {
        return urlAndApprovalId[1]
      }
    },
    init() {
      this.loading = true
      this.showDetails = JSON.parse(localStorage.getItem('approvalShowDetails') || this.showDetails)

      let approvalId = this.getApprovalIdFromUrl()
      if (!approvalId) {
        let approvalIdFromStorage = JSON.parse(localStorage.getItem('approvalId'))
        if (!approvalIdFromStorage) {
          this.loading = false
          return
        }
        approvalId = approvalIdFromStorage
      }

      Api.getClientApproval(approvalId)
          .then(response => {
            this.onlineSignature = response.data.onlineSignature
            this.agencyClientApprovalId = response.data.agencyClientApprovalId
            this.views = response.data.meta ? JSON.parse(response.data.meta) : []
            localStorage.setItem('approvalId', JSON.stringify(this.agencyClientApprovalId))
            this.loadLogo(response.data.agencyClientApprovalId)
          })
          .finally(() => this.loading = false)
    },
    getClientIP() {
      Api.getClientIP()
          .then(response => this.clientIP = response.data)
          .catch(() => {/** TODO */})
    },
    addView(url) {
      let foundView = this.views.find(view => view.url === url)

      if (foundView) {
        return
      }

      let view = {
        url: url,
        approved: false,
        approvalDate: null,
        clientIP: this.clientIP
      }

      this.views.push(view)
      this.updateMeta()
    },
    viewAlreadyApproved(url) {
      return !!this.views.find(view => view.url === url && view.approved)
    },
    toggleDetails() {
      this.showDetails = !this.showDetails
      localStorage.setItem('approvalShowDetails', JSON.stringify(this.showDetails))
    },
    approveCurrentView(currentView) {
      let approvedView = this.views.find(view => view.url === currentView)

      if (this.viewAlreadyApproved(approvedView.url)) {
        return
      }

      approvedView.approved = true
      approvedView.approvalDate = new Date()
      this.updateMeta()
    },
    updateMeta() {
      this.loading = true
      Api.updateApprovalMeta(this.agencyClientApprovalId, JSON.stringify(this.views))
          .catch(() => {/** TODO */})
          .finally(() => this.loading = false)
    },
    updateSignature(image) {
      this.onlineSignature.contractor.signatureImage = image
    },
    sign() {
      if (this.agencyClientApprovalId) {

        this.loading = true
        let signatureDTO = {
          contractorSignatureLine: this.onlineSignature.contractor.signatureLine,
          contractorSignatureImage: this.onlineSignature.contractor.signatureImage,
        }

        Api.sign(this.agencyClientApprovalId, signatureDTO)
            .then(response => {
              this.onlineSignature = response.data.onlineSignature
              this.showModal = false
            })
            .catch(() => {/** TODO */})
            .finally(() => this.loading = false)
      }
    },
    loadLogo(approvalId) {
      this.loading = true
      Api.getLogo(approvalId)
          .then(response => (this.logo = response.data))
          .catch(() => {/** TODO */})
          .finally(() => (this.loading = false))
    }
  },
}
</script>

<style scoped lang="scss">
@import "./styles/theme";
</style>
