import axios from 'axios'

const AXIOS = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    Accept: 'application/json',
    ContentType: 'application/json',
  }
})

export default {
  getClientIP() {
    return AXIOS.get(`api/wc/agency-web-approval/ip`)
  },
  sign(id, signatureDTO) {
    return AXIOS.post(`api/cockpit/resources/agency/client-approval/${id}/sign`, signatureDTO)
  },
  updateApprovalMeta(id, meta) {
    return AXIOS.patch(`api/cockpit/resources/agency/client-approval/${id}/meta`, meta)
  },
  getClientApproval(id) {
    return AXIOS.get(`api/cockpit/resources/agency/client-approval/${id}/sign`)
  },
  getLogo(approvalId) {
    return AXIOS.get(`api/cockpit/resources/agency/client-approval/${approvalId}/agency-logo`)
  },
}
